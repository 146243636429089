@import "@sundae/gov-toolkit/dist/styles.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    .active-nav-item > span {
        @apply text-secondary-200;
    }

    span.status-new {
        @apply bg-secondary-300;
    }

    span.status-ongoing {
        @apply bg-primary-300;
    }
}